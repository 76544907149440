/**
 * Common DOM utilities
 */
import { parseQuery } from '../../common/parseQuery';
import { IWidgetDataset } from '../../types/sdk';
import { IQuery } from '../../types/session.types';

/**  Types  */
declare global {
    interface HTMLElement {
        [s: string]: any;
        attachEvent?: (event: string, handler: () => void) => void;
    }
}

interface INewElementOptions {
    tag: string;
    parent?: Element;
    classes?: Array<string> | string;
    content?: string;
    options?: IKeyValue<any>;
    style?: Partial<CSSStyleDeclaration>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type IEventListenerFunction = (event: Event, a1?: any, a2?: any, a3?: any) => void;

interface IKeyValue<T> {
    [key: string]: T;
}

/**  Globals  */
const QUERY_LOCATION = ['location', 'locations'];
const DATASET_LOCATION = 'data-filter-location';
const QUERY_TAG = ['tags', 'tag'];
const DATASET_TAG = 'data-filter-tag';

/**
 * Get element by id
 */
export function getById(id: string): HTMLElement | null {
    return document.getElementById(id);
}

/**
 * Get elements by class name, or id
 */
export function getCollection(
    class_name: string,
    parent?: HTMLElement
): HTMLCollectionOf<HTMLElement> {
    const elements = parent
        ? parent.getElementsByClassName(class_name)
        : document.getElementsByClassName(class_name);

    return elements as HTMLCollectionOf<HTMLElement>;
}

/**
 * Get elements by tag name
 */
export function getTagCollection(
    input_tag_name: string,
    parent?: HTMLElement
): HTMLCollectionOf<HTMLElement> {
    const tag_name = input_tag_name.toUpperCase();

    const elements = parent
        ? parent.getElementsByTagName(tag_name)
        : document.getElementsByTagName(tag_name);

    return elements as HTMLCollectionOf<HTMLElement>;
}

/**
 * Get parent of a child element
 */
export function getParent(
    child: HTMLElement,
    cls?: string | null,
    tag?: string
): HTMLElement | null {
    if (!child.parentNode) {
        return null;
    }

    const e = child;

    if (cls && cls.indexOf('#') === 0 && e.id && e.id === cls.slice(1)) {
        return e;
    } else if (cls && e.classList && e.classList.contains(cls)) {
        return e;
    }
    if (tag && e.tagName === tag) {
        return e;
    }

    const parent = e.parentNode as HTMLElement | null;

    if (parent && (parent.classList || parent.tagName)) {
        return getParent(parent, cls, tag);
    }

    return null;
}

/**
 * Create a new HTML element
 */
export function newElement({
    tag,
    parent,
    classes: input_classes,
    content,
    options,
    style,
}: INewElementOptions): HTMLElement {
    const e = document.createElement(tag.toUpperCase());

    if (input_classes) {
        const classes =
            input_classes.constructor !== Array
                ? (input_classes as string).split(' ')
                : input_classes;

        for (let i = 0; i < classes.length; i++) {
            if (classes[i]) {
                e.classList.add(classes[i]);
            }
        }
    }

    //  textContent
    if (content && typeof content === 'string') {
        e.textContent = content;
    }

    //  Options
    if (options) {
        for (const k in options) {
            if (typeof options[k] === 'object') {
                // @ts-ignore-next-line
                if (!e[k]) {
                    // @ts-ignore-next-line
                    e[k] = {};
                }

                for (const j in options[k]) {
                    // @ts-ignore-next-line
                    e[k][j] = options[k][j];
                }
            } else {
                // @ts-ignore-next-line
                e[k] = options[k];
            }
        }
    }

    //  Style
    if (style) {
        for (const s in style) {
            e.style[s] = style[s] || '';
        }
    }

    //  Append to parent
    if (parent) {
        parent.appendChild(e);
    }

    return e;
}

/**
 * Add event listener
 */
export function addEvent(
    input_el: HTMLElement | HTMLElement[] | Window,
    type: string,
    fn: IEventListenerFunction,
    capture?: boolean
): void {
    const el = (input_el.constructor !== Array ? [input_el] : input_el) as HTMLElement[];

    // John Resig's addEvent : http://ejohn.org/projects/flexible-javascript-events/
    for (let i = 0; i < el.length; i++) {
        if (!el[i]) {
            continue;
        }

        //  A: addEventListener
        // @ts-ignore
        if (el[i].addEventListener) {
            el[i].addEventListener(type, fn, capture);
        }

        //  B: attachEvent
        else if (el[i].attachEvent) {
            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
            const ie_global_event: string = 'e' + type + fn;
            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
            const ie_unique_key: string = type + fn;

            el[i][ie_global_event] = fn;

            el[i][ie_unique_key] = (): void => {
                el[i][ie_global_event](window.event);
            };

            // @ts-ignore
            el[i].attachEvent('on' + type, el[i][ie_unique_key]);
        }
    }
}

/**
 * Add event listeners to all elements of a class
 */
export function eventClass(
    cl: string,
    type: string,
    fn: IEventListenerFunction,
    parent?: HTMLElement
): void {
    const cl_elements = getCollection(cl, parent);

    for (let i = 0; i < cl_elements.length; i++) {
        addEvent(cl_elements[i], type, fn);
    }
}

/**
 * Make an HTTP request
 */
export function httpRequest<Request, Response>({
    method,
    body,
    url,
    with_credentials = false,
}: {
    method: 'POST' | 'GET';
    body: Request;
    url: string;
    with_credentials?: boolean;
}): Promise<Response> {
    return new Promise((resolve, reject) => {
        const xmlhttp: XMLHttpRequest = window.XMLHttpRequest
            ? new XMLHttpRequest()
            : // @ts-ignore
              new window.ActiveXObject('Microsoft.XMLHTTP');

        /**
         * 1: Prepare request
         */
        xmlhttp.open(method, url, true);
        xmlhttp.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');

        if (with_credentials) {
            xmlhttp.withCredentials = true;
        }

        /**
         * 2: ReadyState onChange
         * - NB: Cannot be arrow function! Need `this`
         */
        xmlhttp.onreadystatechange = function(): void {
            if (this.readyState === 4 && this.status && this.status <= 299) {
                const response = this.responseText;
                let res;
                try {
                    res = JSON.parse(response);
                } catch (error) {
                    //  Do nothing
                }

                resolve(res);
            } else if (this.readyState === 4) {
                reject({
                    message: this.responseText || 'Request failed',
                    statusCode: this.status || 500,
                });
            }
        };

        /**
         * 3: Send request
         */
        xmlhttp.send(JSON.stringify(body));
    });
}

/**
 * Make a POST request
 */
export function postRequest<Request, Response>({
    body,
    url,
    with_credentials = false,
}: {
    body: Request;
    url: string;
    with_credentials?: boolean;
}): Promise<Response> {
    return new Promise((resolve, reject) => {
        httpRequest<Request, Response>({
            method: 'POST',
            body,
            url,
            with_credentials,
        })
            .then(resolve)
            .catch(reject => {
                reject(reject);
            });
    });
}

/**
 * Assemble an element's dataset
 */
export function buildDataset<T extends IWidgetDataset = IWidgetDataset>(
    el: HTMLElement,
    strip_prefix = false
): T {
    const query = getQuery();
    const dataset: IWidgetDataset = {};

    //  dataset via URL
    for (const k in query) {
        if (query[k] && QUERY_LOCATION.includes(k)) {
            dataset[DATASET_LOCATION] = decodeURIComponent(query[k] as string);
        } else if (query[k] && QUERY_TAG.includes(k)) {
            dataset[DATASET_TAG] = decodeURIComponent(query[k] as string);
        }
    }

    //  dataset via element data-* dataset
    for (const k in el.attributes) {
        const attribute = !isNaN((k as unknown) as number) ? el.attributes[k] : k;

        if (typeof attribute === 'string') {
            continue;
        }

        if (attribute.name && attribute.name.indexOf('data-') === 0 && !dataset[attribute.name]) {
            dataset[attribute.name] = attribute.value === 'true' ? true : attribute.value || true;
        }
    }

    //  Strip prefixes?
    if (strip_prefix) {
        for (const k in dataset) {
            if (k.indexOf('data-') === 0) {
                const nk = k.replace('data-', '');
                dataset[nk] = dataset[k];
                delete dataset[k];
            }
        }
    }

    return dataset as T;
}

/**
 * Parse URL query parameters
 */
export function getQuery(url = document.URL): IQuery {
    return parseQuery(url);
}

export function isNiceJobDebugMode(): boolean {
    return !!getQuery(document.URL)[`nicejob-debug`];
}

/**
 * Because some sites have over-ridden default JSON functionality
 */
export function jsonMethods(): void {
    if (typeof JSON === 'undefined' || !JSON) {
        // @ts-ignore-next-line
        // eslint-disable-next-line no-global-assign
        JSON = {};
    }

    // @ts-ignore-next-line
    JSON.stringify = JSON.stringify || JSON.toJSONString || JSON.serialize;
    // @ts-ignore-next-line
    JSON.parse = JSON.parse || JSON.parseJSON || JSON.deserialize;
}

/**
 * Smooth scrolling
 * @param {HTMLElement} el - Element to scroll within
 * @param {number} from - Vertical start point
 * @param {number} to - Vertical end point
 * @param {boolean} [horizontal] - If horizontal scroll
 * @param {number} [other_axis_position] - Scroll position on other axis
 * @param {number} [dt] - Time to scroll
 */
export function smoothVerticalScroll({
    el,
    from: y0,
    to: y1,
    horizontal = false,
    other_axis_position = 0,
    dt = 200,
}: {
    el: HTMLElement;
    from: number;
    to: number;
    horizontal?: boolean;
    other_axis_position?: number;
    dt?: number;
}): void {
    const time_interval = 20;

    const dy = y1 - y0;

    const scroller: {
        start: number;
        y0: number;
        dy: number;
    } = {
        start: Date.now(),
        y0: y0,
        dy,
    };

    const set_interval = setInterval(() => {
        const elapsed = Date.now() - scroller.start;

        //  Scroll by yStep
        const dStep = y0 + Math.round(dy * easingX(elapsed / dt));
        horizontal
            ? el.scrollTo(dStep, other_axis_position)
            : el.scrollTo(other_axis_position, dStep);

        //  Quit if we've reached target
        if (dStep === y1 || (dy > 0 && dStep >= y1) || (dy < 0 && dStep <= y1) || elapsed > dt) {
            clearInterval(set_interval);

            //  Scroll to exact point
            horizontal
                ? el.scrollTo(y1, other_axis_position)
                : el.scrollTo(other_axis_position, y1);
        }
    }, time_interval);
}

function easingX(t: number): number {
    // Feed in a proportional time b/w 0 and 1
    if (t <= 0) {
        return 0;
    }

    if (t >= 1) {
        return 1;
    }

    return t < 0.5 ? 2 * t * t : -1 + 2 * (2 - t) * t;
}
